/*
 * samba2020 - Webpage for https://samba.ccns.sbg.ac.at
 * Copyright (C) 2021. Thomas Hartmann <thomas.hartmann@th-ht.de>
 *
 * This file is part of samba2020.
 *
 * samba2020 is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * samba2020 is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with samba2020.  If not, see <http://www.gnu.org/licenses/>.
 */

import $ from 'jquery';
import leaflet from 'leaflet';
import 'leaflet-providers';

function init_map(div) {
    const x = parseFloat($(div).data('location-x'));
    const y = parseFloat($(div).data('location-y'));
    const conference_title = $(div).data('conference-title');
    const zoom_level = 16;

    const parent = $(div).parent().parent();
    if (parent.prop('tagName') === 'DIV' && parent.hasClass('rich-text')) {
        parent.css('height', '100%');

        const grandparent = parent.parent();
        if (grandparent.prop('tagName') === 'DIV' && grandparent.hasClass('block-paragraph')) {
            grandparent.css('height', '100%');
        }
    }

    const map = leaflet.map(div).setView([x, y], zoom_level);
    leaflet.tileLayer.provider('OpenStreetMap.Mapnik').addTo(map);
    const marker = leaflet.marker([x, y]).addTo(map);
    const osm_url = `https://www.openstreetmap.org/directions?from=&to=${x}%2C${y}#map=${zoom_level}/${x}/${y}`;
    marker.bindPopup(`<a href="${osm_url}" target="_blank">${conference_title}</a>`).openPopup();
}

delete leaflet.Icon.Default.prototype._getIconUrl;
leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

$('.conference-location-map').each(function(idx, obj) {
    init_map(obj);
});
