/*
 * samba2020 - Webpage for https://samba.ccns.sbg.ac.at
 * Copyright (C) 2019-2021 Thomas Hartmann <thomas.hartmann@th-ht.de>
 *
 * This file is part of samba2020.
 *
 * samba2020 is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * samba2020 is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with samba2020.  If not, see <http://www.gnu.org/licenses/>.
 */

import 'bootstrap';
import 'bootstrap-submenu';
import './lib/js/conference_location';

document.addEventListener('DOMContentLoaded', () => {
    require('bootstrap-cookie-alert');
    $('[data-submenu]').submenupicker();
    const tables = document.querySelectorAll('table:not(.table)');
    tables.forEach((element) => {
        element.classList.add('table');
    });

    document.getElementById('reset_filter_button')?.addEventListener('click', () => {
        const form = document.getElementById('filter_form');
        const inputs = form.querySelectorAll('input');
        const selects = form.querySelectorAll('select');

        inputs.forEach(function (input, idx) {
            input.value = null;
        });

        selects.forEach((select) => {
            select.selectedIndex = 0;
        });

        form.submit();
    });

    const turn_table_links_into_buttons = JSON.parse(document.getElementById('turn_table_links_into_buttons').textContent);
    if (turn_table_links_into_buttons) {
        document.querySelectorAll('table').forEach((table) => {
            for (let idx_row = 1; idx_row < table.rows.length; idx_row++) {
                const cur_row = table.rows[idx_row];
                for (let idx_cell = 0; idx_cell < cur_row.cells.length; idx_cell++) {
                    const cur_cell = cur_row.cells[idx_cell];
                    const cur_text = cur_cell.innerHTML.trim();
                    try {
                        const url = new URL(cur_text);
                        const header = table.rows[0].cells[idx_cell].innerHTML.trim()
                        cur_cell.innerHTML = '<a class="btn btn-primary" href="' + url + '">' + header + '</a>'
                    } catch {
                    }
                }
            }
        })
    }
});